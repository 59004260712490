/* eslint-disable react/no-unknown-property */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import * as React from "react"
import useRect from "utils/useRect"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { theme } from "common/colorScheme"
import Container from "components/container"
import useHorizontalSwipe from "utils/useHorizontalSwipe"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import Media, { MediaProps } from "components/Media"

interface ReviewProps {
	caption: {
		caption: string
	}
	image: MediaProps
	logo: MediaProps
	reviewerName: string
	reviewerTitle: string
	id: string
	kicker: string
	link: {
		slug: string
	}
}

export interface IReviewsProps {
	reviews: ReviewProps[]
	whiteTheme: boolean
	interval?: number
}

interface IIndicatorProps {
	active?: boolean
	whiteTheme?: boolean
}

interface IReviewsWrapperProps {
	translateX: number
}

interface IReviewStyleProps {
	width: number
}

interface IBlackTextProps {
	blackText: boolean
}

const reviewsStyle = css`
	width: 100%;
	margin-top: ${theme.spacing(6)};
	color: ${theme.color.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	${mediaBreakpoint(BreakPoints.SM)} {
		margin-top: ${theme.spacing(9)};
	}
`

const reviewsContainer = css`
	width: 100%;
`

const linkWrapper = css`
	text-decoration: none;
	color: unset;
`

const ReviewsWrapper = styled.div<IReviewsWrapperProps>`
	display: flex;
	width: fit-content;
	transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
	transform: ${({ translateX }) => `translateX(-${translateX}px)`};
`

const ReviewStyle = styled.div<IReviewStyleProps>`
	width: ${({ width }) => `${width}px`};
`

const reviewContainer = css`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
		text-align: initial;
	}
`

const reviewImageContainer = css`
	min-width: 227px;
	width: 100%;
	max-width: 400px;
	margin-bottom: ${theme.spacing(5)};

	${mediaBreakpoint(BreakPoints.LG)} {
		margin-right: ${theme.spacing(12)};
		margin-bottom: 0;
		min-width: 300px;
	}
`

const reviewImage = css`
	height: 227px;
	width: 227px;

	${mediaBreakpoint(BreakPoints.SM)} {
		height: 300px;
		width: 300px;
	}
`

const reviewContentContainer = css`
	display: flex;
	flex-direction: column;
	align-items: center;

	${mediaBreakpoint(BreakPoints.LG)} {
		align-items: flex-start;
	}
`

export const reviewQuote = css`
	color: ${theme.color.teal};
	font-size: 5rem;
	line-height: 5rem;
	font-weight: 800;
`

const ReviewContent = styled.div<IBlackTextProps>`
	font-size: 2.4rem;
	line-height: 3.2rem;
	font-weight: 800;
	margin-bottom: ${theme.spacing(4.75)};
	color: ${({ blackText }) => blackText && theme.color.black};

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 3.6rem;
		line-height: 4.6rem;
	}
`

const ReviewAuthor = styled.div<IBlackTextProps>`
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin-bottom: ${theme.spacing(2)};
	display: flex;
	flex-direction: column;
	color: ${({ blackText }) => blackText && theme.color.black};

	span {
		font-weight: 800;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
		span {
			margin-right: 5px;
		}
	}
`

const reviewLogo = css`
	max-height: 20px;
	max-width: 100px;
`

const indicatorContainer = css`
	display: flex;
	margin-top: ${theme.spacing(5.5)};
`

const IndicatorWrapper = styled.div`
	padding: 20px;
	cursor: pointer;
`

const Indicator = styled.div<IIndicatorProps>`
	height: 12px;
	width: 12px;
	background-color: ${({ whiteTheme }) => (whiteTheme ? theme.color.black : theme.color.white)};
	opacity: ${({ active }) => (active ? 1 : 0.2)};
	transition: opacity 0.2s ease-in-out;
	border-radius: 50%;
`

const Reviews: React.FC<IReviewsProps> = ({ reviews, whiteTheme, interval }) => {
	const [active, setActive] = React.useState(0)
	const [translateX, setTranslateX] = React.useState(0)

	const containerRef = React.useRef(null)
	const { width } = useRect(containerRef)

	const logo = whiteTheme ? "logoBlack" : "logoWhite"
	const intervalTime = interval ?? 9000

	const handleIndicatorClick = (target: number) => setActive(target)
	const handleSwipeLeft = () => setActive(active === 0 ? reviews.length - 1 : active - 1)
	const handleSwipeRight = () => setActive(active === reviews.length - 1 ? 0 : active + 1)

	useHorizontalSwipe(containerRef, handleSwipeLeft, handleSwipeRight, 50)

	React.useEffect(() => {
		setTranslateX(width * active)

		const switchInterval = setInterval(handleSwipeRight, intervalTime)
		return () => clearInterval(switchInterval)
	}, [active, width])

	return (
		<div css={reviewsStyle}>
			<div css={reviewsContainer} ref={containerRef}>
				<ReviewsWrapper translateX={translateX}>
					{reviews.map(review => {
						const { id, link, image, caption, reviewerName, reviewerTitle } = review
						const logoFile = review[logo]

						return (
							<ReviewStyle key={id} width={width}>
								<a href={`/${link ? link?.slug : ""}/`} css={linkWrapper}>
									<Container>
										<div css={reviewContainer}>
											<div css={reviewImageContainer}>
												<Media css={reviewImage} {...image} />
											</div>
											<div css={reviewContentContainer}>
												<div css={reviewQuote}>“</div>
												<ReviewContent blackText={whiteTheme}>{caption?.caption ? caption?.caption : null}</ReviewContent>
												<ReviewAuthor blackText={whiteTheme}>
													<span>{reviewerName},</span>
													{reviewerTitle}
												</ReviewAuthor>
												<Media css={reviewLogo} {...logoFile} />
											</div>
										</div>
									</Container>
								</a>
							</ReviewStyle>
						)
					})}
				</ReviewsWrapper>
			</div>
			<div css={indicatorContainer}>
				{reviews.map((_, i) => (
					<IndicatorWrapper onMouseDown={() => handleIndicatorClick(i)} key={i}>
						<Indicator active={active === i} whiteTheme={whiteTheme} />
					</IndicatorWrapper>
				))}
			</div>
		</div>
	)
}

export default Reviews
